import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c2597eee"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "solution-station"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_t_solution_banner = _resolveComponent("t-solution-banner");
  const _component_t_solution_service = _resolveComponent("t-solution-service");
  const _component_t_story_card = _resolveComponent("t-story-card");
  const _component_t_more_service = _resolveComponent("t-more-service");
  const _component_t_solution_footer = _resolveComponent("t-solution-footer");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_t_solution_banner, {
    type: _ctx.solutionType.station
  }, null, 8, ["type"]), _createVNode(_component_t_solution_service, {
    type: _ctx.solutionType.station
  }, null, 8, ["type"]), _createVNode(_component_t_story_card, {
    class: "story-card",
    "story-list": _ctx.Stores
  }, null, 8, ["story-list"]), _createVNode(_component_t_more_service, {
    types: [_ctx.solutionType.common]
  }, null, 8, ["types"]), _createVNode(_component_t_solution_footer, {
    class: "solution-footer"
  })]);
}