import { defineComponent } from 'vue';
import { useHead } from '@vueuse/head';
import { ISolutionTypeEnum } from '../config';
import { Stores } from '../storyConfig';
import TSolutionService from '../SolutionCommon/components/SolutionService.vue';
import TSolutionFooter from '@/components/SolutionBase/SolutionFooter.vue';
import TSolutionBanner from '@/components/SolutionBase/SolutionBanner.vue';
import TMoreService from '@/components/SolutionBase/MoreService.vue';
import TStoryCard from '@/components/SolutionBase/StoryCard.vue';
import { SOLUTION_SEO_CONFIG } from '@/base/config/seo';
import { apmLog } from '@/base/hooks/useApm/apmLog';
export default defineComponent({
  name: 'TSolutionStation',
  components: {
    TSolutionBanner,
    TSolutionService,
    TStoryCard,
    TMoreService,
    TSolutionFooter
  },
  setup() {
    apmLog();
    useHead(SOLUTION_SEO_CONFIG);
    return {
      solutionType: ISolutionTypeEnum,
      Stores
    };
  }
});