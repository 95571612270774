import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { SOLUTION_BASE_INFO, SERVICE_CARD_CONFIG } from '../../config';
import { sliceArr } from '@/base/utils';
export default defineComponent({
  name: 'TService',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const colSpan = 3;
    const cardList = sliceArr((SERVICE_CARD_CONFIG === null || SERVICE_CARD_CONFIG === void 0 ? void 0 : SERVICE_CARD_CONFIG[props.type].list) || [], colSpan);
    return {
      router,
      commonData: (SOLUTION_BASE_INFO === null || SOLUTION_BASE_INFO === void 0 ? void 0 : SOLUTION_BASE_INFO[props.type]) || {},
      cardList
    };
  }
});